const CryptoJS = require('crypto-js'),
pidCrypt = require("pidcrypt");
require("pidcrypt/aes_cbc");


function base64url(source) {
    encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
}

function getJitsiRoomLinkModer(url, room, username) {
    var header = {
        "alg": "HS256",
        "typ": "JWT"
    };

    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = base64url(stringifiedHeader);

    var data = {
        "context": {
            "user": {
                "name": username,
                "affiliation": "owner"
            }

        },
        "aud": "jitsi",
        "iss": "id",
        "sub": "*.reakf.ru",
        "room": room
    };

    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    var encodedData = base64url(stringifiedData);

    var token = encodedHeader + "." + encodedData;

    var secret = 'id369-+';

    var signature = CryptoJS.HmacSHA256(token, secret);
    signature = base64url(signature);

    var signedToken = token + "." + signature;



//? Присоединение парметров в config.js и interfaceConfig к jwt токену 22 - двойная кавычка,  %2C - запятая , %5B и %D являются [ и ]соответственно.

    return  url + "/" + room + "?jwt=" + signedToken + "#config.startWithAudioMuted=true&config.prejoinPageEnabled=true&config.readOnlyName=false&config.remoteVideoMenu.disableKick=false&interfaceConfig.TOOLBAR_BUTTONS=%5B%22microphone%22%2C%22camera%22%2C%22desktop%22%2C%22fullscreen%22%2C%22fodeviceselection%22%2C%22hangup%22%2C%22chat%22%2C%22toggled%22%2C%22etherpad%22%2C%22sharedvideo%22%2C%22shareaudio%22%2C%22settings%22%2C%22raisehand%22%2C%22videoquality%22%2C%22filmstrip%22%2C%22feedback%22%2C%22stats%22%2C%22evenodd%22%2C%22tileview%22%2C%22select-background%22%2C%22download%22%2C%22help%22%2C%22mute-everyone%22%2C%22mute-video-everyone%22%2C%22participants-pane%22%2C%22security%22%5D";


    // Пример :
    // + "#config.startWithVideoMuted=true&doNotStoreRoom=true&interfaceConfig.TOOLBAR_BUTTONS=%5B%22invite%22%5D"
}



function getJitsiRoomLinkUser(url, room) {
    var header = {
        "alg": "HS256",
        "typ": "JWT"
    };

    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = base64url(stringifiedHeader);

    var data = {
        "context": {
            "user": {
                "affiliation": "member"
            }
        },
        "aud": "jitsi",
        "iss": "id",
        "sub": "*.reakf.ru",
        "room": room

    };

    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    var encodedData = base64url(stringifiedData);

    var token = encodedHeader + "." + encodedData;

    var secret = 'id369-+';

    var signature = CryptoJS.HmacSHA256(token, secret);
    signature = base64url(signature);

    var signedToken = token + "." + signature;

 
    return url + "/" + room + "?jwt=" + signedToken + "#config.startWithAudioMuted=true&config.prejoinPageEnabled=true&config.readOnlyName=false&interfaceConfig.TOOLBAR_BUTTONS=%5B%22microphone%22%2C%22camera%22%2C%22desktop%22%2C%22fullscreen%22%2C%22fodeviceselection%22%2C%22hangup%22%2C%22chat%22%2C%22toggled%22%2C%22etherpad%22%2C%22sharedvideo%22%2C%22shareaudio%22%2C%22raisehand%22%2C%22videoquality%22%2C%22filmstrip%22%2C%22feedback%22%2C%22stats%22%2C%22evenodd%22%2C%22tileview%22%2C%22select-background%22%2C%22download%22%2C%22help%22%2C%22participants-pane%22%5D";
}

var name = document.getElementById('name'),
    nameInn= document.querySelector('._name'),    
    room = document.getElementById('room'),
    roomInn = document.querySelector('._room'),
    btn = document.querySelector('.form__btn'),
    textOutput = document.querySelector('.text__output'),
    textOutputSlide= document.querySelector('.text__output-slide'),
    formBlock = document.querySelector('.form__block'),
    closeElem = document.querySelector('.text__output-close'),
    inputBlock = document.querySelectorAll('._ident ');

const textOutputHeader = textOutput.querySelectorAll('h2'),
    textOutputSubheader = textOutput.querySelectorAll('h3'),
    textOutputLink = textOutput.querySelectorAll('.link');

const roomNameSlide = document.querySelector("#room-name__slide");




const link = document.querySelectorAll(".link-go"),
      linkInviteCopy = document.querySelectorAll(".link-go-invite");



function myHref() {
    link.forEach(param => {
        return param;
    });
}  


function myHrefM(param) {
    link[0]['value'] = param;
}  

function myHrefU(param) {
    link[1]['value'] = param;
}  

function myHrefInviteM(param) {
    linkInviteCopy[0]['value'] = param ;
}  

function myHrefInviteU(param) {
    linkInviteCopy[1]['value'] = param ;
}  


var formReq = document.querySelectorAll('._req');



formBlock.addEventListener('submit', function(e) {
    e.preventDefault();
    for (let i= 0; i < formReq.length; i++) {
        if (formReq[i].value){
            formReq[i].classList.remove('_error');
        }
    }

    for (let i= 0; i < formReq.length; i++) {
        if (!formReq[i].value){
            formReq[i].classList.add('_error');
            formReq[i].value = "";
        } 
    }

    if (!letterTestName(formReq[0].value))
    { 
        formReq[0].classList.add('_error'); 
        nameInn.style = "color: rgb(0, 99, 199)";
        nameInn.innerHTML = "Введите корректное имя";
        formReq[0].value = "";
    } else {
        nameInn.style = "font-size: 16px";
        nameInn.innerHTML = "Имя";
        formReq[0].classList.remove('_error');
    }
    
    if (!letterTestRoom(formReq[1].value))
    { 
        formReq[1].classList.add('_error'); 
        roomInn.style = "color:rgb(0, 99, 199);";
        roomInn.innerHTML = "Введите название комнаты";
        formReq[1].value = "";
    } else {
        roomInn.style = "font-size: 16px";
        roomInn.innerHTML = "Название комнаты";
        formReq[1].classList.remove('_error');
    }


    var errors = formBlock.querySelectorAll('._error');
    if (errors.length == 0) {


        const selected = document.querySelector('input[name="radio"]:checked').value;
        myHrefM(getJitsiRoomLinkModer(selected, encodeURIComponent(room.value.split(' ').join('_').replace(/[?&:'"%#№]+/,'').toLowerCase()), name.value));
        myHrefU(getJitsiRoomLinkUser(selected, encodeURIComponent(room.value.split(' ').join('_').replace(/[?&:'"%#№]+/,'').toLowerCase()))); 

        console.log(myHref());


        // console.log(selected);

        myHrefInviteM( name.value + " приглашает вас на запланированную конференцию JITSI\nТема: " + room.value + "\n\n" + "Подключиться к конференции как модератор " + shortLink(getJitsiRoomLinkModer(selected, encodeURIComponent(room.value.split(' ').join('_').replace(/[?&:'"%#№]+/,'').toLowerCase()))));
        myHrefInviteU( name.value + " приглашает вас на запланированную конференцию JITSI\nТема: " + room.value + "\n\n" + "Подключиться к конференции как участник " + shortLink(getJitsiRoomLinkUser(selected, encodeURIComponent(room.value.split(' ').join('_').replace(/[?&:'"%#№]+/,'').toLowerCase()))));
        myHrefRoomName(room.value);


        textOutput.style = "opacity:1; z-index: 3;";
        textOutput.classList.add('text__output-slide');
        textOutput.classList.remove('text__output-outslide');
        


        for (let i= 0; i < textOutputHeader.length; i++){
            textOutputHeader[i].style = "animation: showText 0.6s forwards;";
        }
        for (let i= 0; i < textOutputSubheader.length; i++) {
            textOutputSubheader[i].style = "animation: showText 0.6s forwards";

            roomNameSlide.style = "animation: showText 0.6s forwards";
        }
        for (let i= 0; i < textOutputLink.length; i++) {
            textOutputLink[i].style = "animation: showText 0.6s forwards;";
            roomNameSlide.style = "animation: showText 0.6s forwards";
        }
        closeElem.style = "animation: showClose 0.8s forwards;";
    }
});

function letterTestName(input) {
    const regex = /[a-zA-Zа-яёА-ЯЁ]+/;
    return (regex.test(input));
}

function letterTestRoom(input) {
    const regex = /.+/;
    return (regex.test(input));
}





//! Закрытие выплывающего меню


closeElem.addEventListener('click', () => {
    btn.style.opacity = "1";
    textOutput.style = "opacity: 0; z-index: -2;";
    textOutput.classList.remove('text__output-slide');


    for (let i= 0; i < textOutputHeader.length; i++){
        textOutputHeader[i].style = "animation: hideText 0s forwards;z-index:-10; height :0px;";
    }

    for (let i= 0; i < textOutputSubheader.length; i++) {
        textOutputSubheader[i].style = "animation: hideText 0s forwards;z-index:-10; height :0px;";

    } 

    for (let i= 0; i < textOutputLink.length; i++) {
        textOutputLink[i].style = "animation: hideText 0s forwards; z-index:-10; height :0px;";
        roomNameSlide.style = "opacity: 0;display:none;z-index:-10; height :0px;";
    }
    
    textOutput.classList.add('text__output-outslide');
});


//TODO Сократить ссылку с помощью сервися от Яндекса clck.ru


function shortLink(link){
    var XMLHttpRequest= require('xhr2');
    let xhr = new XMLHttpRequest();

    //? encodeURIComponent нужно использовать,чтобы сервер кликера воспринимал знаки # и &, которые передаются в парметрах ссылки

    xhr.open( "GET", "https://clck.ru/--?url=" + encodeURIComponent(link), false );
    xhr.send();
    return xhr.response;
}


//TODO Название комнаты в выплывающем меню


function myHrefRoomName(param) {
    roomNameSlide['value'] = param;
}  


//TODO Прелоадер на сайте


document.body.onload = 
    setTimeout(function(){
        var preloader = document.getElementById("page-preloader");
        if (!preloader.classList.contains("done")) 
        {
          preloader.classList.add("done");
        }
    },1000);

